<template>
  <!-- GEEK9_GEEKTS 에 있는 상품을 스토어에 올리면 해당 건은 GEEK9_GEEKTS 삭제 -->
  <div class="div_title">
    <span style="color:#ff7f50;">조회/등록</span>
  </div>
  <div class="card">
    <div class="div_title2">
      <Button
        style="min-width: 60px"
        v-on:click="click({ type: 'DELETE_GEEKTS_BY_LIST' })"
        label="삭제"
        raised
        size="small"
      />
      <span class="div_title2">
        <!-- checkmark :highlightOnSelect="false" class="order_sub_table" -->
        <Select v-model="status" :options="statusList" optionLabel="name" style="height:35px;"/>
        &nbsp;
        <Button
          style="min-width: 60px"
          v-on:click="click({ type: 'SELECT_GEEKTS', page: 1 })"
          label="조회"
          raised
          size="small"
        />
        &nbsp;
        <Button
          style="min-width: 60px"
          v-on:click="click({ type: 'UPDATE_GEEKTS_BY_LIST' })"
          label="수정"
          raised
          size="small"
        />
        &nbsp;
        <input type="file" id="inputCarousellFile" ref="inputCarousellFile" @change="readCarousellFile" style="max-width: 0px;margin-bottom: 5px;" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
        <label class="p-button p-component p-button-raised p-button-sm" for="inputCarousellFile" style="min-width: 60px" raised>파일</label>
      </span>
    </div>
  </div>

  <div class="card">
    <div class="div_title2">
      <select v-model="regSite" class="normalInput">
        <option value="" selected>사이트</option>
        <option value="smartstore">smartstore</option>
        <option value="coupang">coupang</option>
        <option value="carousell">carousell</option>
      </select>
      <select v-model="saleType" class="normalInput">
        <option value="" selected>SALE_TYPE</option>
        <option value="NEW">새제품</option>
        <option value="OLD">중고</option>
      </select>
      <InputText
        class="normalInput"
        v-model="extraTitle"
        placeholder="제목에 추가"
      />
    </div>
    <br />
    <div class="div_title2">
      <InputText
        class="normalInput"
        v-model="country"
        placeholder="국가"
      />
      <InputText
        class="normalInput"
        v-model="brand"
        placeholder="브랜드"
      />
      <InputText
        class="normalInput"
        v-model="keyword"
        placeholder="키워드"
      />
    </div>
    <br />
    <div class="div_title2">
      <span>
        <AutoComplete
          v-model="category"
          :suggestions="items"
          @complete="suggest"
          placeholder="카테고리"
        />
        <Button
          v-on:click="click({ type: 'adjust' })"
          label="일괄적용"
          raised
          size="small"
        />
      </span>
    </div>
  </div>
  <div class="div_title">
    <span style="color:#ff7f50;">GEEKTs <label style="color:black;">({{ storeReadyList.length }}건)</label></span> 
    <InputText
      style="width:60px;"
      class="normalInput"
      v-model="offset"
      placeholder="offset"
    />
  </div>
  <div class="card">
    <DataTable
      v-model:selection="selectedProduct"
      v-model:expandedRows="expandedRows"
      selectionMode="multiple"
      :metaKeySelection="metaKey"
      :value="storeReadyList"
      dataKey="IDX"
      @rowExpand="onRowExpand"
      striped
    >
      <template #header>
        <div class="flex flex-wrap justify-end gap-2">
          <Button
            text
            icon="pi pi-plus"
            label="Expand All"
            @click="expandAll"
          />
          <Button
            text
            icon="pi pi-minus"
            label="Collapse All"
            @click="collapseAll"
          />
        </div>
      </template>
      <Column expander style="max-width: 5px;" />
      <Column header="Image">
        <template #body="slotProps" >
          <div style="text-align: center;">
            <img
              :src="slotProps.data.IMG_URL"
              :alt="slotProps.data.NAME"
              class="shadow-lg"
              width="64"
            /><br>
            <a :href="`${slotProps.data.URL}`" target="_blank">클릭</a>
          </div>
        </template>
      </Column>
      <Column field="price" header="Price">
        <template #body="slotProps">
          <div style="text-align: center">
            €{{ slotProps.data.ORIGIN_PRICE }}<br />
          </div>
        </template>
      </Column>
      <Column header="카테고리">
        <template #body="slotProps">
          <!-- <input type="text" style="width:80px;text-align:center;" v-model="category.split('>').slice(-1)[0].split('-')[0]" disabled><br>
          <input type="text" style="width:80px;text-align:center;" v-model="category.split('>').slice(-1)[0].split('-')[1]" disabled> -->

          <input
            type="text"
            style="width: 80px; text-align: center"
            :value="slotProps.data.CATEGORY_NAME"
            disabled
          /><br />
          <input
            type="text"
            style="width: 80px; text-align: center"
            :value="slotProps.data.CATEGORY_ID"
            disabled
          />
        </template>
      </Column>
      <Column header="상태">
        <template #body="slotProps">
          <Select :options="statusList" optionLabel="name" style="height:35px;width:auto;" :modelValue="getCurrentStatus(slotProps.data.STATUS)" @change="handleStatusChange($event, slotProps.data)"/>
        </template>
      </Column>
      <template #expansion="slotProps">
        <div style="background-color: #f9f9f9">
          <table class="dynamic-table" style="margin-bottom: 0px">
            <tr style="height: 100px">
              <td>
                <table class="order_sub_table" style="table-layout: fixed">
                  <tbody style="text-align: left">
                    <tr tr style="height: 30px">
                      <td class="container_sub_style">
                        <div class="div_center order-div">
                          <div>IDX</div>
                          <div>{{ slotProps.data.IDX }}</div>
                        </div>
                      </td>
                      <td class="container_sub_style">
                        <div class="div_center order-div">
                          <div>등록일</div>
                          <div>
                            {{
                              this.api.getDateByFormat(
                                slotProps.data.REG_DATE,
                                "YYYY.MM.DD"
                              )
                            }}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr tr style="height: 30px">
                      <td class="container_sub_style">
                        <div class="div_center order-div">
                          <div>상품명</div>
                          <div>{{ slotProps.data.NAME }}</div>
                        </div>
                      </td>
                      <td class="container_sub_style">
                        <div class="div_center order-div">
                          <div>브랜드</div>
                          <div>{{ slotProps.data.BRAND }}</div>
                        </div>
                      </td>
                    </tr>
                    <tr style="height: 30px">
                      <td class="container_sub_style">
                        <div class="div_center order-div">
                          <div>해외가격</div>
                          <div>{{ slotProps.data.ORIGIN_PRICE }}</div>
                        </div>
                      </td>
                      <td class="container_sub_style">
                        <div class="div_center order-div">
                          <div>상태</div>
                          <div>
                            {{ slotProps.data.SALE_TYPE }}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr style="height: 30px">
                      <td class="container_sub_style">
                        <div class="div_center order-div">
                          <div>사이즈</div>
                          <div>{{ slotProps.data.SIZE }}</div>
                        </div>
                      </td>
                      <td class="container_sub_style">
                        <div class="div_center order-div">
                          <div>키워드</div>
                          <div>
                            {{ slotProps.data.KEYWORD }}
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr tr style="height: 30px">
                      <td class="container_sub_style">
                        <div class="div_center order-div">
                          <div>카테고리</div>
                          <div>{{ slotProps.data.CATEGORY_NAME }}</div>
                        </div>
                      </td>
                      <td class="container_sub_style">
                        <div class="div_center order-div">
                          <div>카테고리ID</div>
                          <div>{{ slotProps.data.CATEGORY_ID }}</div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </table>
        </div>
      </template>
    </DataTable>
    <br />
    <div class="div_center">
      <label @click="paging('prev')" class="link"> 이전 </label>
      <span style="margin: 20px">{{ page }}</span>
      <label @click="paging('next')" class="link"> 다음 </label>
    </div>
  </div>
</template>
<script>
import { ref } from "vue"
const xlsx = require('xlsx')

export default {
  setup() {
    const status = ref({name:'전체', code:0})
    const statusList = ref([{name:'전체', code:0}, {name:'미예약', code:100},{name:'예약',code:101},{name:'게시',code:102},{name:'제외',code:99}])
    const category = ref("")
    const items = ref([])
    const selectedProduct = ref([])
    const metaKey = ref(true)
    const carousellList = ref([])
    
    const storeReadyList = ref([])
    const expandedRows = ref({})
    const expandAll = () => {
      expandedRows.value = storeReadyList.value.reduce(
        (acc, p) => (acc[p.IDX] = true) && acc,
        {}
      )
    }
    const collapseAll = () => {
      expandedRows.value = null
    }
    const onRowExpand = (event) => {
      expandedRows.value = { [event.data.IDX]: true }
    }
    return {
      onRowExpand,
      storeReadyList,
      expandAll,
      collapseAll,
      expandedRows,
      category,
      statusList,
      items,
      selectedProduct,
      metaKey,
      carousellList,
      status
    }
  },
  components: {},
  mounted() {},
  data() {
    return {
      brand:'',
      country:'',
      keyword:'',
      rsrvYn: "",
      saleType: "OLD",
      extraTitle: "",
      regSite: "smartstore",
      page: 1,
      offset:10,
      categoryList: [],
    }
  },
  methods: {
    readCarousellFile(event) {
      this.regSite = 'carousell'
      if (this.category === "") {
        this.emitter.emit("confirm", {
          message: "카테고리를 선택해주세요.",
          header: "카테고리",
          icon: "pi pi-exclamation-triangle",
        })
        return
      } else if(this.keyword === '') {
        this.emitter.emit("confirm", {
          message: "키워드를 입력해주세요.",
          header: "등록",
          icon: "pi pi-exclamation-triangle",
        })
        return
      }
      let orders = {}
      const reader = new FileReader()
      const file = event.target.files[0]

      reader.onload = async (e) => {
        event.target.value=''
        let data = e.target.result
        data = new Uint8Array(data)
        let excelFile = xlsx.read(data, { type: "array" })
        
        excelFile.SheetNames.forEach(function(sheetName) {
          const roa = xlsx.utils.sheet_to_json(excelFile.Sheets[sheetName], { header: 1 } )
          if (roa.length) orders[sheetName] = roa
        })
        let keys = Object.keys(orders)
        if(keys.length === 0) {
          alert('엑셀 파일에 주문 내역이 없습니다')
          this.$notify('엑셀 파일에 주문 내역이 없습니다')
          event.target.value=''
        } else {
          const key = keys[0]
          this.orderList = orders[key]
          const key0 = this.orderList.shift()[0]
          const key1 = this.orderList.shift()[0]
          const key2 = this.orderList.shift()[0]
          const key3 = this.orderList.shift()[0]
          this.carousellList = []
          const selectedCate = this.category.split("-")
                      
          for(let i = 0; i < this.orderList.length; i += 4) {
            try {
              this.carousellList.push({
                [key0]: key0 === 'name' ? this.orderList[i][0].replace(/"/g, "'").match(/[a-zA-Z]+/g)?.join('') : this.orderList[i][0].replace(/"/g, "'"),
                [key1]: key1 === 'name' ? this.orderList[i+1][0].replace(/"/g, "'").match(/[a-zA-Z]+/g)?.join('') : this.orderList[i+1][0].replace(/"/g, "'"),
                [key2]: key2 === 'name' ? this.orderList[i+2][0].replace(/"/g, "'").match(/[a-zA-Z]+/g)?.join('') : this.orderList[i+2][0].replace(/"/g, "'"),
                [key3]: key3 === 'name' ? this.orderList[i+3][0].replace(/"/g, "'").match(/[a-zA-Z]+/g)?.join('') : this.orderList[i+3][0].replace(/"/g, "'"),
                type:this.regSite,
                brand:this.brand,
                country:this.country,
                keyword:this.keyword,
                categoryName:selectedCate[0],
                categoryId:selectedCate[1]
              })
            } catch (err) {
              continue
            }
          }
          const formData = new FormData()
          let list = await this.api.list2String(this.carousellList)
          
          formData.append("list", list)
          formData.append("type", "INSERT_GEEKTS_LIST")
          
          await this.api.axiosGql("INSERT_GEEKTS_LIST", formData)
          .then( async (res) => {
            if(res.status === 200) {
              this.emitter.emit("confirm", {
                message: "등록되었습니다",
                header: "등록",
                icon: "pi pi-info-circle",
              })
            } else {
              this.emitter.emit("confirm", {
                message: "다시 시도해주세요.",
                header: "조회",
                icon: "pi pi-exclamation-triangle",
              })
            }
          })
          .catch( (err)=> { 
            console.log(err)
            this.emitter.emit("confirm", {
              message: "다시 시도해주세요.",
              header: "조회",
              icon: "pi pi-exclamation-triangle",
            })
          })
        }
      }
      reader.readAsArrayBuffer(file)
    },
    async handleStatusChange(event, rowData) {
      if(confirm('수정하시겠습니까?')) {
        const type = "UPDATE_GEEKTS_STATUS"
        await this.api.axiosGql(type, { type: type, idx:rowData.IDX, status:event.value.code })
        .then(async (res) => {
          if(res.status === 200) {
            this.click({ type: 'SELECT_GEEKTS', page: 1 })
          }
        })
      }
    },
    getCurrentStatus(status) {
      const foundStatus = this.statusList.find(item => item.code === status)
      return foundStatus || null
    },
    clear() {
      this.category = ''
      this.extraTitle = ''
      this.selectedProduct = []
    },
    async suggest(event) {
      if (this.categoryList.length === 0) {
        const type = "SELECT_STORE_CATE"
        await this.api.axiosGql(type, { type: type, storeType: this.regSite })
        .then(async (res) => {
          const json = res.data
          if (res.status !== 200 || json === undefined || json.length === 0)
            alert("카테고리를 읽어 오지 못했습니다")
          else
            this.categoryList = json
            .map((item) => `${item.TOTAL_NAME}-${item.ID}`)
            .join(",")
        })
      }
      this.items = this.categoryList
        .split(",")
        .filter((item) => item.includes(event.query))
    },
    async search(page) {
      return this.api.axiosGql("SELECT_GEEKTS", {
        type: "SELECT_GEEKTS",
        page: page,
        offset: this.offset,
        status:this.status.code
      })
    },
    paging(type) {
      if (type === "prev") {
        if (this.page === 1) {
          alert("첫 페이지입니다.")
          return
        } else this.page -= 1
      } else this.page += 1
      this.search(this.page)
        .then(async (res) => {
          if (res.data.length === 0) {
            this.page -= 1
            alert("마지막 페이지입니다.")
          } else {
            this.storeReadyList = res.data
          }
        })
        .catch((err) => {
          console.log(err)
          if (type === "prev") this.page += 1
          else this.page -= 1
        })
    },
    async click(param) {
      const type = param.type

      if (type === "SELECT_GEEKTS") {
        this.page = param.page
        this.storeReadyList = []
        await this.search(this.page)
          .then(async (res) => {
            if (res.status === 200) {
              this.storeReadyList = res.data
              if (this.storeReadyList.length === 0) {
                this.emitter.emit("confirm", {
                  message: "검색 결과가 없습니다.",
                  header: "조회",
                  icon: "pi pi-exclamation-triangle",
                })
              }
            } else
              this.emitter.emit("confirm", {
                message: "다시 시도해주세요.",
                header: "조회",
                icon: "pi pi-exclamation-triangle",
              })
          })
          .catch((err) => {
            console.log(err)
            this.emitter.emit("confirm", {
              message: "다시 시도해주세요.",
              header: "조회",
              icon: "pi pi-exclamation-triangle",
            })
          })
      } else if (type === "adjust") {
        if (this.category === "") {
          this.emitter.emit("confirm", {
            message: "카테고리를 선택해주세요.",
            header: "카테고리",
            icon: "pi pi-exclamation-triangle",
          })
        } else {
          if (this.selectedProduct.length === 0) {
            this.emitter.emit("confirm", {
              message: "상품을 선택해주세요.",
              header: "수정",
              icon: "pi pi-exclamation-triangle",
            })
          } else {
            const selectedCate = this.category
              .split("-")
            for (let i = 0; i < this.selectedProduct.length; i++) {
              this.selectedProduct[i].CATEGORY_NAME = selectedCate[0]
              this.selectedProduct[i].CATEGORY_ID = selectedCate[1]
            }
          }
        }
      } else if (type === "DELETE_GEEKTS_BY_LIST") {
        if (this.selectedProduct.length === 0)
          this.emitter.emit("confirm", {
            message: "삭제할 상품을 선택해주세요.",
            header: "삭제",
            icon: "pi pi-exclamation-triangle",
          })
        else {
          if (confirm("삭제할까요?")) {
            let delList = []
            for (let i = 0; i < this.selectedProduct.length; i++) {
              delList.push(this.selectedProduct[i].IDX)
            }
            await this.api.axiosGql(type, {
              type: type,
              list: delList,
            })
            .then(async (res) => {
              if (res.status === 200) {
                this.emitter.emit("confirm", {
                  message: "삭제되었습니다",
                  header: "삭제",
                  icon: "pi pi-info-circle",
                })
                this.clear()
                this.click({ type: "SELECT_GEEKTS", page: this.page })
              } else {
                this.emitter.emit("confirm", {
                  message: "다시 시도해주세요.",
                  header: "삭제",
                  icon: "pi pi-exclamation-triangle",
                })
              }
            })
            .catch((err) => {
              console.log(err)
              this.emitter.emit("confirm", {
                message: "다시 시도해주세요.",
                header: "삭제",
                icon: "pi pi-exclamation-triangle",
              })
            })
          }
        }
      } else if (type === "UPDATE_GEEKTS_BY_LIST") {
        if (confirm("수정할까요?")) {
          if (this.selectedProduct.length === 0) {
            this.emitter.emit("confirm", {
              message: "수정할 상품을 선택해주세요.",
              header: "수정",
              icon: "pi pi-exclamation-triangle",
            })
          } else if(this.status.code === undefined || this.status.code === 0) {
              this.emitter.emit("confirm", {
                message: "수정할 상태를 선택해주세요.",
                header: "수정",
                icon: "pi pi-exclamation-triangle",
              })
          } else {
            let flag = true
            let param = []
            for (let i = 0; i < this.selectedProduct.length; i++) {
              const rsrv = this.selectedProduct[i]
              const json = {
                regType: this.regSite,
                saleType: this.saleType,
                originPrice: rsrv.ORIGIN_PRICE,
                name:
                  this.extraTitle === ""
                    ? rsrv.NAME
                    : this.extraTitle + " - " + rsrv.NAME,
                keyword: rsrv.KEYWORD,
                idx: rsrv.IDX,
                categoryName: rsrv.CATEGORY_NAME,
                categoryId: rsrv.CATEGORY_ID,
                brand: rsrv.BRAND,
                status: this.status.code
              }
              
              param.push(json)
              if ((rsrv.CATEGORY_ID === null || rsrv.CATEGORY_NAME === null) && this.status.code === 101) {
                this.emitter.emit("confirm", {
                  message: "카테고리 정보가 누락되었습니다..",
                  header: "수정",
                  icon: "pi pi-exclamation-triangle",
                })
                flag = false
                break
              }
            }
            if (flag === true) {
              await this.api.axiosGql(type, {
                type: type,
                list: param,
              })
              .then(async (res) => {
                if (res.status === 200) {
                  this.emitter.emit("confirm", {
                    message: "수정되었습니다",
                    header: "수정",
                    icon: "pi pi-info-circle",
                  })
                  this.clear()
                  this.status = {name:'미예약', code:100}
                  this.click({ type: "SELECT_GEEKTS", page: this.page })
                } else {
                  this.emitter.emit("confirm", {
                    message: "다시 시도해주세요.",
                    header: "수정",
                    icon: "pi pi-exclamation-triangle",
                  })
                }
              })
              .catch((err) => {
                console.log(err)
                this.emitter.emit("confirm", {
                  message: "다시 시도해주세요.",
                  header: "수정",
                  icon: "pi pi-exclamation-triangle",
                })
              })
              this.click({ type: "SELECT_GEEKTS", page: this.page })
            }
          }
        }
      }
    },
  },
}
</script>

<style scoped>
</style>