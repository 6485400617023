<template>
  <div class="div_title">
    <span style="color:#ff7f50;">카페 등록</span>
  </div>
  <table class="card">
    <tr>
      <td style="width: 100%">
        <div class="div_title2">
          <Select v-model="menuId" :options="cafeMenuList" optionLabel="name" placeholder="카페게시판" style="margin-right:5px;" />
          <InputText
            class="normalInput"
            v-model="regUrl"
            v-on:keyup.enter="click({ type: 'reg' })"
            placeholder="VINTED URL"
            type="url"
          />
        </div>
      </td>
      <td style="min-width: 110px">
        <Button
          v-on:click="click({ type: 'store', regSite:'naverCafe' })"
          label="등록"
          raised
          size="small"
          style="margin-right: 5px"
        />
        <Button
          v-on:click="this.click({type:'SELECT_STORE_UPLOAD', page:1, regSite:'naverCafe'})"
          label="조회"
          raised
          size="small"
        />
      </td>
    </tr>
  </table>
  <div class="card">
    <table class="dynamic-table" style="margin-bottom: 0px">
      <tr style="height: 100px">
        <td>
          <table class="order_sub_table" style="table-layout: fixed">
            <tbody style="text-align: left">
              <tr tr style="height: 30px">
                <td class="container_sub_style">
                  <div class="div_center order-div">
                    <div>브랜드</div>
                    <div>
                      <InputText class="normalInput" v-model="brand" placeholder="브랜드" />
                    </div>
                  </div>
                </td>
                <td class="container_sub_style">
                  <div class="div_center order-div">
                    <div>사이즈</div>
                    <div>
                      <InputText
                        class="normalInput"
                        v-model="size"
                        placeholder="사이즈"
                      />
                    </div>
                  </div>
                </td>
              </tr>
              <!--
              <tr tr style="height: 30px">
                <td class="container_sub_style">
                  <div class="div_center order-div">
                    <div>추가제목</div>
                    <div>
                      <InputText
                        class="normalInput"
                        v-model="extraTitle"
                        placeholder="제목에 추가"
                      />
                    </div>
                  </div>
                </td>
                <td class="container_sub_style">
                  <div class="div_center order-div">
                    <div>등록사이트</div>
                    <div>
                      <select v-model="regSite" class="normalInput">
                        <option value="" selected>등록사이트</option>
                        <option value="smartstore">스마트스토어</option>
                        <option value="coupang">쿠팡</option>
                      </select>
                    </div>
                  </div>
                </td>
              </tr>
              <tr style="height: 30px">
                <td class="container_sub_style">
                  <div class="div_center order-div">
                    <div>교환/취소/환불비용</div>
                    <div>
                      <InputNumber
                        class="normalInput"
                        v-model="fee"
                        placeholder="교환/취소/환불"
                      />
                    </div>
                  </div>
                </td>
                <td class="container_sub_style">
                  <div class="div_center order-div">
                    <div>국내택배사</div>
                    <div>
                      <select v-model="deliveryCompany" class="normalInput">
                        <option value="" selected>택배사</option>
                        <option value="EPOST">우체국</option>
                      </select>
                    </div>
                  </div>
                </td>
              </tr>
              <tr style="height: 30px">
                <td class="container_sub_style">
                  <div class="div_center order-div">
                    <div>재고</div>
                    <div>
                      <InputNumber
                        class="normalInput"
                        v-model="stockQuantity"
                        placeholder="재고"
                      />
                    </div>
                  </div>
                </td>
                <td class="container_sub_style">
                  <div class="div_center order-div">
                    <div>상품유형</div>
                    <div>
                      <select v-model="productType" class="normalInput">
                        <option value="">상품유형</option>
                        <option value="WEAR">WEAR</option>
                      </select>
                    </div>
                  </div>
                </td>
              </tr>
              <tr style="height: 30px">
                <td class="container_sub_style">
                  <div class="div_center order-div">
                    <div>사이즈</div>
                    <div>
                      <InputText
                        class="normalInput"
                        v-model="size"
                        placeholder="사이즈"
                      />
                    </div>
                  </div>
                </td>
                <td class="container_sub_style">
                  <div class="div_center order-div">
                    <div>국내배송비</div>
                    <div>
                      <InputNumber
                        class="normalInput"
                        v-model="deliveryPrice"
                        placeholder="배송비"
                      />
                    </div>
                  </div>
                </td>
              </tr>
              -->
              <tr style="height: 30px">
                <td class="container_sub_style" colspan="2">
                  <div class="div_center order-div">
                    <div>카테고리</div>
                    <div>
                      <AutoComplete
                        class="normalInput"
                        v-model="category"
                        :suggestions="items"
                        @complete="suggest"
                        placeholder="카테고리"
                      />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </table>
  </div> 
  <div class="div_title">
    <span style="color:#ff7f50;">카페등록상품(STORE_UPLOAD) <label style="color:black;">{{ this.cafeList.length }}건</label></span> 
    <InputText
      style="width:60px;"
      class="normalInput"
      v-model="offset"
      placeholder="offset"
    />
  </div>
  <div class="card">
    <div class="no-result" v-if="cafeList.length === 0">
      검색 결과가 없습니다.
    </div>
    <div v-else>
      <DataTable
        v-model:selection="selectedProduct"
        v-model:expandedRows="expandedRows"
        selectionMode="multiple"
        :metaKeySelection="metaKey"
        :value="cafeList"
        dataKey="IDX"
        @rowExpand="onRowExpand"
        striped
      >
        <template #header>
          <div class="flex flex-wrap justify-end gap-2">
            <Button
              text
              icon="pi pi-plus"
              label="Expand All"
              @click="expandAll"
            />
            <Button
              text
              icon="pi pi-minus"
              label="Collapse All"
              @click="collapseAll"
            />
          </div>
        </template>
        <Column expander style="width: 5rem" />
        <Column header="사진">
          <template #body="slotProps">
            <img
              :src="slotProps.data.IMG_URL.split(',')[0]"
              :alt="slotProps.data.image"
              class="shadow-lg"
              width="64"
            />
          </template>
        </Column>
        <Column field="CATEGORY_NAME" header="카테고리"></Column>
        <Column field="price" header="가격">
          <template #body="slotProps">
            <div style="text-align: center">
              €{{ slotProps.data.ORIGIN_PRICE }}<br />
              ₩{{ Number(slotProps.data.SELL_PRICE).toLocaleString() }}
            </div>
          </template>
        </Column>
        <Column field="BRAND" header="브랜드" />
        <Column field="COUNTRY" header="국가" />
        <template #expansion="slotProps">
          <div style="background-color: #f9f9f9">
            <table class="dynamic-table" style="margin-bottom: 0px">
              <tr style="height: 100px">
                <td>
                  <table class="order_sub_table" style="table-layout: fixed">
                    <tbody style="text-align: left">
                      <tr tr style="height: 30px">
                        <td class="container_sub_style">
                          <div class="div_center order-div">
                            <div>IDX</div>
                            <div>{{ slotProps.data.IDX }}</div>
                          </div>
                        </td>
                        <td class="container_sub_style">
                          <div class="div_center order-div">
                            <div>등록일</div>
                            <div>
                              {{
                                this.api.getDateByFormat(
                                  slotProps.data.REG_DATE,
                                  "YYYY.MM.DD"
                                )
                              }}
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr tr style="height: 30px">
                        <td class="container_sub_style">
                          <div class="div_center order-div">
                            <div>카페주소</div>
                            <div>
                              <a :href="this.api.CAFE_STORE_URL+slotProps.data.CODE" target="_blank">{{ slotProps.data.CODE }}</a>
                            </div>
                          </div>
                        </td>
                        <td class="container_sub_style">
                          <div class="div_center order-div">
                            <div>브랜드</div>
                            <div>{{ slotProps.data.BRAND }}</div>
                          </div>
                        </td>
                      </tr>
                      <tr style="height: 30px">
                        <td class="container_sub_style">
                          <div class="div_center order-div">
                            <div>해외가격</div>
                            <div>{{ slotProps.data.ORIGIN_PRICE }}</div>
                          </div>
                        </td>
                        <td class="container_sub_style">
                          <div class="div_center order-div">
                            <div>상품명</div>
                            <div>
                              {{ slotProps.data.NAME }}
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr style="height: 30px">
                        <td class="container_sub_style">
                          <div class="div_center order-div">
                            <div>URL</div>
                            <div>
                              <a :href="slotProps.data.URL" target="_blank">클릭</a>
                            </div>
                          </div>
                        </td>
                        <td class="container_sub_style">
                          <div class="div_center order-div">
                            <div>사이즈</div>
                            <div>{{ slotProps.data.SIZE }}</div>
                          </div>
                        </td>
                      </tr>
                      <tr tr style="height: 30px">
                        <td class="container_sub_style">
                          <div class="div_center order-div">
                            <div>카테고리</div>
                            <div>{{ slotProps.data.CATEGORY_NAME }}</div>
                          </div>
                        </td>
                        <td class="container_sub_style">
                          <div class="div_center order-div">
                            <div>국가</div>
                            <div>{{ slotProps.data.COUNTRY }}</div>
                          </div>
                        </td>
                      </tr>
                      <tr tr style="height: 30px">
                        <td class="container_sub_style">
                          <div class="div_center order-div">
                            <div>채널</div>
                            <div>{{ slotProps.data.TYPE }}</div>
                          </div>
                        </td>
                        <td class="container_sub_style">
                          <div class="div_center order-div">
                            <div>게시판</div>
                            <div>{{ this.api.getCateNameById(slotProps.data.PRODUCT_TYPE) }}({{ slotProps.data.PRODUCT_TYPE }})</div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </table>
          </div>
        </template>
      </DataTable>
      <br />
      <div class="div_center">
        <label @click="paging('prev')" class="link"> 이전 </label>
        <span style="margin: 20px">{{ page }}</span>
        <label @click="paging('next')" class="link"> 다음 </label>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue"

export default {
  setup() {
    const category = ref("")
    const items = ref([])
    const cafeList = ref([])
    const selectedProduct = ref([])
    const metaKey = ref(true)
    const expandedRows = ref({})
    const expandAll = () => {
      expandedRows.value = cafeList.value.reduce(
        (acc, p) => (acc[p.IDX] = true) && acc,
        {}
      )
    }
    const collapseAll = () => {
      expandedRows.value = null
    }
    const onRowExpand = (event) => {
      expandedRows.value = { [event.data.IDX]: true }
    }
    // const getSeverity = (product) => {
    //   switch (product.inventoryStatus) {
    //     case "INSTOCK":
    //       return "success"

    //     case "LOWSTOCK":
    //       return "warn"

    //     case "OUTOFSTOCK":
    //       return "danger"

    //     default:
    //       return null
    //   }
    // }
    return {
      onRowExpand,
      cafeList,
      // getSeverity,
      expandAll,
      collapseAll,
      expandedRows,
      selectedProduct,
      metaKey,
      category,
      items,
    }
  },
  components: {},
  mounted() {},
  data() {
    return {
      cafeMenuList:[
        { name: "전체" },
        ...this.api.CAFE_MENU_LIST
      ],
      menuId:{},
      size:"",
      extraTitle: "",
      brand: "",
      offset:10,
      regUrl: "",
      page: 1,
      categoryList: [],
    }
  },
  methods: {
    paging(type) {
      if (type === "prev") {
        if (this.page === 1) {
          alert("첫 페이지입니다.")
          return
        } else this.page -= 1
      } else this.page += 1
      this.search({page:this.page, regSite:'naverCafe'})
      .then(async (res) => {
        if (res.data.length === 0) {
          this.page -= 1
          alert("마지막 페이지입니다.")
        } else {
          this.cafeList = res.data
        }
      })
      .catch((err) => {
        console.log(err);
        if (type === "prev") this.page += 1
        else this.page -= 1
      })
    },
    async suggest(event) {
      if (this.categoryList.length === 0) {
        const type = "SELECT_STORE_CATE"
        await this.api.axiosGql(type, { type: type, storeType: this.regSite })
        .then(async (res) => {
          const json = res.data
          if (res.status !== 200 || json === undefined || json.length === 0)
            alert("카테고리를 읽어 오지 못했습니다")
          else
            this.categoryList = json
              .map((item) => `${item.TOTAL_NAME}-${item.ID}`)
              .join(",")
        })
      }
      this.items = this.categoryList
        .split(",")
        .filter((item) => item.includes(event.query))
    },
    async click(param) {
      const type = param.type
      if (type === "store") {
        if (this.category === "" || this.category === undefined)
          this.emitter.emit("confirm", {
            message: "카테고리를 선택해주세요.",
            header: "상품등록",
            icon: "pi pi-exclamation-triangle",
          })
        else if (this.regUrl === "")
          this.emitter.emit("confirm", {
            message: "URL을 입력해주세요.",
            header: "상품등록",
            icon: "pi pi-exclamation-triangle",
          })
        else if (this.menuId.id === undefined || this.menuId.id === '')
          this.emitter.emit("confirm", {
            message: "게시판을 선택해주세요.",
            header: "상품등록",
            icon: "pi pi-exclamation-triangle",
          })
        else {
          this.emitter.emit("dialog", { flag: true, title: "등록중입니다." })
          const regSite = param.regSite
          const code = this.api.getCookies('naverCode')
          const state = this.api.getCookies('naverState')
          let token = this.api.getCookies('naverToken')
          if(token === null || token === 'null' || token === undefined || token === '') {
            await this.api.axiosGql("SELECT_NAVER_TOKEN", {type:"SELECT_NAVER_TOKEN", code:code, state:state})
            .then( async (naverVal) => {
              const t = naverVal.data
              if(t === undefined) {
                alert('다시 시도해주세요')
              } else {
                this.api.setCookies('naverToken', t)
                token = t
              }
            })
            .catch( (err) => {
              console.log(err)
              alert('다시 시도해주세요')
            })
          }
          if(token === '') {
            this.emitter.emit("dialog", { flag: false })
            alert('token이 유효하지 않습니다')
          } else {
            await this.api.axiosGql(type, {
              type: type,
              brand: this.brand,
              site: regSite,
              url: this.regUrl,
              size: this.size,
              extraTitle: this.extraTitle,
              categoryTotalName: this.category.split("-")[0],
              categoryId: this.category.split("-")[1],
              code:code,
              state:state,
              menuId:this.menuId.id,
              token:token
            })
            .then(async (result) => {
              if (result.status === 200) {
                if(regSite === 'naverCafe') {
                  const productNo = result.data
                  const url = `${this.api.CAFE_STORE_URL}${productNo}`
                  
                  if((url === undefined || url === '' || url === null)) {
                    this.emitter.emit("confirm", {
                      message: "다시 시도해주세요.",
                      header: "등록",
                      icon: "pi pi-exclamation-triangle",
                    })
                  } else {
                    this.emitter.emit("confirm", {
                      message: "등록되었습니다",
                      header: "등록",
                      icon: "pi pi-info-circle",
                    })
                    this.click({type:'SELECT_STORE_UPLOAD', page:1, regSite:'naverCafe'})
                  }
                }
              } else if(result.status === 201) {
                const errMsg = result.data.error
                this.emitter.emit("confirm", {
                  message: errMsg,
                  header: "등록",
                  icon: "pi pi-exclamation-triangle",
                })
                this.$emit('logout')
              }
            })
            .catch((err) => {
              console.log(err)
            })
            .finally(() => {
              this.emitter.emit("dialog", { flag: false })
            })
          }
        }
      } else if (type === "SELECT_STORE_UPLOAD") {
        this.page = param.page
        const regSite = param.regSite
        this.cafeList = []
        await this.search({page:this.page, regSite:regSite})
        .then(async (res) => {
          if (res.status === 200) {
            this.cafeList = res.data
          } else
            this.emitter.emit("confirm", {
              message: "다시 시도해주세요.",
              header: "상품조회",
              icon: "pi pi-exclamation-triangle",
            })
        })
      }
    },
    async search(param) {
      return this.api.axiosGql("SELECT_STORE_UPLOAD", {
        type: "SELECT_STORE_UPLOAD",
        regType: param.regSite,
        productType: this.menuId.id,
        page: param.page,
        offset:this.offset
      })
    },
  },
}
</script>

<style scoped>
</style>