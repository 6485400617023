<template>
  <div class="div_title">
    <span>
      <span style="color: #ff7f50;">&#9654;</span> 
      <select style="text-align:center;margin-left:5px; padding:5px;" v-model="contractType" @change="changed()"> 
        <option value="company">업체</option>
        <option value="item">아이템</option>
        <option value="forwarder">포워더</option>
      </select>
    </span>
    <Button  v-on:click="click({type:'INSERT'})" size="small" label="등록" />  
  </div>
  <table v-if="contractType === 'company'" class="table card" style="width:100%; ">
    <tr style="height:40px;text-align: center;">
      <td style="width:25%">업체명</td>
      <td style="width:25%"><input ref="name" type="text" placeholder="업체명"/></td>
      <td style="width:25%;text-align: center;">사업자번호</td>
      <td style="width:25%"><input ref="num" type="number" placeholder="사업자번호"/></td>
    </tr>
    <tr style="height:40px;text-align: center;">
      <td>대표자명</td>
      <td><input ref="ceo" type="text" placeholder="대표자명"/></td>
      <td>연락처</td>
      <td ><input ref="phone" type="number" placeholder="연락처"/></td>
    </tr>
    <tr style="height:40px;text-align: center;">
      <td>우편번호</td>
      <td ><input ref="addrZip" type="number" placeholder="우편번호"/></td>
      <td >도로명주소</td>
      <td ><input ref="addrRoad" type="text" placeholder="도로명주소"/></td>
    </tr>
    <tr style="height:40px;text-align: center;">
      <td>상세주소</td>
      <td><input ref="addrDetail" type="text" placeholder="상세주소"/></td>
      <td >기타</td>
      <td><input ref="etc" type="text" placeholder="기타"/></td>
    </tr>  
  </table>
  <table v-else-if="contractType === 'item'" class="table card" style="width:100%;">
    <tr style="height:40px;text-align: center;">
      <td style="width:25%">사업자명</td>
      <td style="width:25%">
        <select class="select-contract"  v-model="company" ref="company_select_box"> 
          <option value="">선택</option>
          <option v-for="(company, index) in companyList" :value="company.NUM" v-bind:key="index">{{company.NAME}}</option>
        </select>
      </td>
      <td style="width:25%;text-align: center;">아이템명</td>
      <td style="width:25%"><input ref="itemName" type="text" placeholder="아이템명"/></td>
    </tr>
    <tr style="height:40px;text-align: center;">
      <td>상품링크</td>
      <td><input ref="url" type="url" placeholder="상품링크"/></td>
      <td>사진링크</td>
      <td ><input ref="imgUrl" type="url" placeholder="사진링크" /></td>
    </tr>
    <tr style="height:40px;text-align: center;">
      <td>판매가격</td>
      <td ><input ref="sellPrice" type="number" placeholder="판매가격"/></td>
      <td >해외가격</td>
      <td ><input ref="originPrice" type="text" placeholder="해외가격"/></td>
    </tr>
    <tr style="height:40px;text-align: center;">
      <td>브랜드</td>
      <td><input ref="brand" type="text" placeholder="상세주소"/></td>
      <td >국가</td>
      <td>
        <select class="select-contract" ref="country">
          <option value = "" selected>국가선택</option>
          <option value = "홍콩">홍콩</option>
          <option value = "프랑스">프랑스</option>
          <option value = "영국">영국</option>
          <option value = "스페인">스페인</option>
          <option value = "독일">독일</option>
          <option value = "이탈리아">이탈리아</option>
          <option value = "일본">일본</option>
          <option value = "중국">중국</option>
          <option value = "미국">미국</option>
          <option value = "싱가포르">싱가포르</option>
          <option value = "네덜란드">네덜란드</option>
          <option value = "호주">호주</option>
          <option value = "뉴질랜드">뉴질랜드</option>
          <option value = "대만" >대만 (+886)</option>
          <!-- <option value = "태국" >태국 (+66)</option> -->
          <!-- <option value = "덴마크">덴마크</option> -->
          <!-- <option value = "베트남">베트남</option> -->
          </select>
      </td>
    </tr>  
  </table>
  <div v-else-if="contractType === 'forwarder'"  >
    <div id="cart-container" class="card">
      <div class="mypage-cart-wrapper"> 
        <p class="div_title2">
          <label>ID</label>
          <InputText v-model="forwarderId" style="font-size:14px;text-align: center;" class="tracking-input" placeholder="ID"/>
        </p>
        <p class="div_title2">
          <label>TYPE</label>
          <InputText v-model="forwarderType" style="font-size:14px;text-align: center;" class="tracking-input" placeholder="TYPE"/>
        </p>
      </div>
      <div class="mypage-cart-wrapper"> 
        <p class="div_title2">
          <label>NAME</label>
          <InputText v-model="forwarderName" style="font-size:14px;text-align: center;" class="tracking-input" placeholder="NAME"/>
        </p>
      </div>
    </div>
    <div class="div_title">
      <span style="color: #ff7f50;">&#9654; <label style="color:black;">조회</label></span>  
      <Button  v-on:click="click({type:'SELECT', page:1})" size="small" label="조회" />
    </div>
    <div v-if="forwarderList === undefined || forwarderList.length === 0" class="margin10 no-result card">
      검색된 결과가 없습니다.
    </div>
    <div v-else>
      <DataTable :value="forwarderList" editMode="cell" @cell-edit-complete="onCellEditComplete"
        :pt="{
          table: { style: 'min-width: 50rem' },
          column: {
            bodycell: ({ state }) => ({
              class: [{ '!py-0': state['d_editing'] }]
            })
          }
        }">
        <Column v-for="col of columns" :key="col.id" :field="col.name" :header="col.name" style="width: 25%">
          <template #body="{ data, field }">
            {{ data[field] }}
          </template>
          <template #editor="{ data, field }">
            <InputText v-model="data[field]" autofocus fluid />
          </template>
        </Column>
      </DataTable>
    </div>
    <div class="div_center">
      <label @click="paging({type:'prev'})" class="link"> 이전 </label>
      <span style="margin: 20px">{{ page }}</span>
      <label @click="paging({type:'next'})" class="link"> 다음 </label>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue'

export default {
  emits: ["setCookies", "setMenu", "login", "logout"],
  setup() {
    const columns = ref([
      { field: 'id', name: 'ID' },
      { field: 'name', name: 'NAME' },
      { field: 'type', name: 'TYPE' }
    ])
    const onCellEditComplete = (event) => {
      let { data, newValue, field } = event
      if (newValue.trim().length > 0) data[field] = newValue
      else event.preventDefault()
    }
    return {
      columns,
      onCellEditComplete
    }
  },
  components: {
  },  
  mounted() {
  },
  data() { 
    return { 
      contractType:'forwarder',
      forwarderId:'',
      forwarderName:'',
      forwarderType:'',
      company:'',
      companyList: Array(),
      forwarderList: [],
      offset:10,
      page:1
    }
  },
  methods: {
    search(param) {
      const type = param.type
      const page = param.page
      if(type === 'SELECT_FORWARDER') {
        return this.api.axiosGql(type, {type:type, page:page, offset:this.offset})
      }
    },
    async paging(param) {
      const type = param.type
      if(type === 'prev') {
        if(this.page === 1) {
          alert('첫 페이지입니다.')
          return
        } else this.page -= 1
      } else this.page += 1
      await this.search({type:'SELECT_FORWARDER', page:this.page})
      .then( async (res) => {
        if(res.data.length === 0) {
          this.page -= 1
          alert('마지막 페이지입니다.')
        } else {
          this.forwarderList = res.data
        }
      }).catch( (err) => {
        console.log(err)
        if(type === 'prev') this.page += 1
        else this.page -= 1
      })
    },
    async click(param) {
      let type = param.type
      const typeTitle = String(this.contractType).toUpperCase()
      type += '_' + typeTitle
      if(type === 'INSERT_COMPANY') {
        const name = this.$refs.name.value
        const num = this.$refs.num.value
        const ceo = this.$refs.ceo.value
        const phone = this.$refs.phone.value
        const addrZip = this.$refs.addrZip.value
        const addrRoad = this.$refs.addrRoad.value
        const addrDetail = this.$refs.addrDetail.value
        const etc = this.$refs.etc.value

        await this.api.axiosGql('INSERT_COMPANY', {type:'INSERT_COMPANY', name:name, num:num, ceo:ceo, phone:phone, addrZip:addrZip, addrRoad:addrRoad, addrDetail:addrDetail, etc:etc})
        .then( async (res) => {
          if(res.data === null) this.$notify('다시 시도해주세요.')  
          else this.$notify(res.data.NAME + '이 등록되었습니다.')
        }).catch( (err) => {
          this.$notify('다시 시도해주세요.')
          console.log(err)
        })
      } else if(type === 'INSERT_ITEM') {
        const num = this.company
        const itemName = this.$refs.itemName.value
        const url = this.$refs.url.value
        const imgUrl = this.$refs.imgUrl.value
        const brand = this.$refs.brand.value
        const country = this.$refs.country.value
        const sellPrice = Number(this.$refs.sellPrice.value)
        const originPrice = this.$refs.originPrice.value
        
        await this.api.axiosGql('INSERT_ITEM', {type:'INSERT_ITEM', num:num, name:itemName, url:url, imgUrl:imgUrl, brand:brand, country:country, sellPrice:sellPrice, originPrice:originPrice})
        .then( async (res) => {
          if(res.data === null) this.emitter.emit('confirm', {message: '다시 시도해주세요.', header:typeTitle, icon:'pi pi-exclamation-triangle'})
          else this.emitter.emit('confirm', {message:res.data.insertItem.NAME + '이 등록되었습니다.', header:typeTitle, icon:'pi pi-info-circle'})
        }).catch( (err) => {
          this.emitter.emit('confirm', {message: '다시 시도해주세요.', header:typeTitle, icon:'pi pi-exclamation-triangle'})
          console.log(err)
        })
      } else if(type === 'INSERT_FORWARDER') {
        const name = this.forwarderName
        const id = this.forwarderId
        const forwarderType = this.forwarderType

        if(name === '' || id === '' || forwarderType === '') 
          this.emitter.emit('confirm', {message: '누락된 값이 있습니다.', header:typeTitle, icon:'pi pi-exclamation-triangle'})
        else {
          await this.api.axiosGql(type, {type:type, name:name, id:id, forwarderType:forwarderType})
          .then( async (res) => {
            if(res.status === 200) this.emitter.emit('confirm', {message:'등록되었습니다.', header:typeTitle, icon:'pi pi-info-circle'})
            else this.emitter.emit('confirm', {message: '다시 시도해주세요.', header:typeTitle, icon:'pi pi-exclamation-triangle'})
          }).catch( (err) => {
            this.emitter.emit('confirm', {message: '다시 시도해주세요.', header:typeTitle, icon:'pi pi-exclamation-triangle'})
            console.log(err)
          })
        }
      } else if(type === 'SELECT_FORWARDER') {
        await this.search({type:type, page:param.page})
        .then( async (res) => {
          this.forwarderList = res.data
        })
      }
    },
    async changed() {
      if(this.contractType === 'item') {
        await this.api.axiosGql('SELECT_COMPANY', {type:'SELECT_COMPANY'})
        .then( async (res) => {
          this.companyList = res.data 
        })
      }
    }
  }
}
</script>

<style scoped>
.select-contract {
  width:100%
}
.div_title2 > label {
  width:50px;
}
</style>